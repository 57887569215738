<template>
    <ul class="custom-ul-list solution-cont font-inter-medium">
        <li><p class="pl-4">Safe & Secure</p></li>
        <li><p class="pl-4">Fast & Reliable</p></li>
        <li><p class="pl-4">Easy Registration Process</p></li>
        <li><p class="pl-4">Data available on past and future auctions</p></li>
        <li><p class="pl-4">Regular Updates of Bank Auctions on E mail</p></li>
<!--        <li><p class="pl-4">Kerala Focused Operations</p></li>-->
        <li><p class="pl-4">Complete data on the properties on auction by the Bank/Fis in Kerala</p></li>
        <li><p class="pl-4">Value Added Services -Legal/Documentation/Valuation/Funding Assitance/Advisory</p></li>
        <li><p class="pl-4">24x7x365 services</p></li>
    </ul>
</template>
<script>
export default {
    name: 'CustomUlList'
};
</script>
<style scoped lang="scss">
.custom-ul-list {
    list-style: none;

    li {
        position: relative;

        &:before {
            content: "";
            background-image: url(../../assets/img/bullets/bullet.png);
            background-size: contain;
            background-repeat: no-repeat;
            width: 15px;
            height: 15px;
            top: 4px;
            left: 0;
            position: absolute;
        }
    }
} </style>
