<template>
    <img src="./assets/Arrow.svg" class="animate-bounce-infinite floating-arrow" alt="">
</template>

<script>
export default {
    name: 'FloatingArrow'
};
</script>

<style scoped lang="scss">
    .animate-bounce-infinite {
        animation: bounce 4s ease infinite;
    }
    .floating-arrow{
       height: 3rem;
    }
    @keyframes bounce {
        0%, 20%, 50%, 80%, 100% {
            transform: translateY(0);
        }
        40% {
            transform: translateY(15px);
        }
        60% {
            transform: translateY(15px);
        }
    }
</style>
