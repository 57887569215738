<template>
    <transition enter-active-class="animated fadeIn" leave-active-class="animated fadeOut" mode="out-in" appear
                duration="300">
        <div class="modal-overlay" ref="backdrop" v-show="visible" @keyup="onKeyUp" tabindex="0">
            <transition :enter-active-class="enterTransitionClass" :leave-active-class="exitTransitionClass"
                        mode="out-in" appear duration="300">
                <div class="modal" :class="modalClass" v-click-outside="backdropClicked" v-if="visible"
                     @keyup="onKeyUp">
                    <div class="modal-header fl-te-c p-4" :class="headerClass">
                        <div></div>
                        <h5 v-if="title" v-html="title" class="lh-1 m-0"></h5>
                        <div class="modal-logo px-md-9 px-6 fl-x fl-a-c">
                            <img src="../../assets/web/logo.png" class="img-fluid" alt="">
                        </div>
                        <a href="javascript:void(0)" class="pl-2 pr-2 round-1" @click="close"><i
                            class="fa fa-close"></i></a>
                    </div>
                    <div class="modal-body" :class="bodyClass">
                        <slot></slot>
                    </div>
                    <div class="modal-footer fl-x fl-rev" :class="footerClass" v-if="dialog && !isFooterHidden">
                        <slot name="footer">
                            <div class="btn-group ">
                                <lego-button :text="cancelButton" v-if="cancelButton" :color="cancelButtonColor"
                                             :design="cancelButtonDesign" @click="cancelClicked"/>
                                <lego-button :text="okButton" :color="okButtonColor" :design="okButtonDesign"
                                             @click="okClicked"/>
                            </div>
                        </slot>
                    </div>
                </div>
            </transition>
        </div>
    </transition>
</template>

<script>
import LegoButton from 'lego-framework/src/components/Button';
import ModalProps from 'lego-framework/src/containers/ModalProps';

export default {
    name: 'CustomModal',
    components: { LegoButton },
    props: {
        ...ModalProps
    },

    data () {
        return {
            visible: false,
            paddingRight: 0,
            overflow: 0,
            resolvePromise: null,
            rejectPromise: null,
            isFooterHidden: false
        };
    },

    computed: {
        enterTransitionClass () {
            return 'animated ' + this.enterAnimation;
        },

        exitTransitionClass () {
            return 'animated ' + this.exitAnimation;
        },

        modalClass () {
            const names = [];
            if (this.color) {
                names.push('bg-' + this.color);
            }
            if (this.width) {
                names.push('w-' + this.width);
            }
            return names;
        },

        headerClass () {
            const names = [];
            if (this.headerColor) {
                names.push('bg-' + this.headerColor);
            }
            return names;
        },

        bodyClass () {
            const names = [];
            if (this.bodyColor) {
                names.push('bg-' + this.bodyColor);
            }
            return names;
        },

        footerClass () {
            const names = [];
            if (this.footerColor) {
                names.push('bg-' + this.footerColor);
            }
            return names;
        }
    },
    methods: {
        show () {
            this.visible = true;
            const body = document.body;
            const scrollbarWidth = window.innerWidth - body.clientWidth;
            const paddingRight = body.style.paddingRight;
            const iPaddingRight = parseInt(paddingRight || '0', 10);
            this.paddingRight = paddingRight;
            this.overflow = body.style.overflowY;

            body.style.overflowY = 'hidden';
            body.style.paddingRight = (scrollbarWidth + iPaddingRight) + 'px';

            if (this.dialog && !this.isCustomDialog) {
                return new Promise((resolve, reject) => {
                    this.resolvePromise = resolve;
                    this.rejectPromise = reject;
                });
            }

            this.$nextTick(function () {
                this.$refs.backdrop.focus();
            });
        },

        hideFooter () {
            this.isFooterHidden = true;
        },

        showFooter () {
            this.isFooterHidden = false;
        },

        backdropClicked (ev) {
            if (this.noCloseOnBackdrop === true) {
                return;
            }

            if (ev.target === this.$refs.backdrop) {
                this.close();
                if (this.dialog && !this.isCustomDialog) {
                    this.resolvePromise(false);
                }
            }
        },

        onKeyUp (ev) {
            if (this.noCloseOnEsc === true) {
                return;
            }

            if (ev.keyCode === 27) {
                this.close();
                if (this.dialog && !this.isCustomDialog) {
                    this.resolvePromise(false);
                }
            }
        },

        close () {
            this.visible = false;
            const body = document.body;
            body.style.overflowY = this.overflow;
            body.style.paddingRight = this.paddingRight;
            // window.scrollTo(0, this.paddingRight);
        },

        okClicked () {
            if (!this.isCustomDialog) {
                this.close();
                this.resolvePromise(true);
            } else {
                this.$emit('okClicked');
            }
        },

        cancelClicked () {
            if (!this.isCustomDialog) {
                this.close();
                this.resolvePromise(false);
            } else {
                this.$emit('cancelClicked');
            }
        }
    }
};
</script>

<style scoped lang="scss">

</style>
