<template>
    <div>
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <heading-with-info class="mb-6" :bottom-tag="true" title="Featured Banks"
                                       info="Most of the commercial Banks and many trusted NBFCs are associated with us to provide our customers with the hassle-free financial services"></heading-with-info>
                </div>
            </div>

            <div class="row">
                <div v-for="(item, index) in details" :key="index" class="col-lg col-md-4">
                    <div class="fl-x fl-a-s py-4 p-5 p-md-0 py-lg-0">
                        <span class="line-right"></span>
                        <img :src="item.icon" alt="" class="img-fluid">
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import HeadingWithInfo from '@components/HeadingWithInfo';

export default {
    name: 'FeaturedBanks',
    props: {
        details: {
            type: Array, default: null
        }
    },
    components: { HeadingWithInfo }
};
</script>

<style scoped lang="scss">
.line-right {
    position: absolute;
    right: 0;
    top: 50%;
    width: 1px;
    height: 3.125rem;
    transform: translateY(-50%);
    background-color: rgba(128, 128, 128, 0.2);
}
</style>
