<template>
    <div>
        <div class="container">
            <heading-with-info class="" :bottom-tag="false" title="Upcoming Auctions"
                               info="Explore a range of exciting upcoming auctions from leading banks. Don't miss the chance to bid on exclusive assets and opportunities. Stay tuned for our latest listings and secure your spot today"/>
        </div>

        <auction-items/>
    </div>
</template>

<script>
import HeadingWithInfo from '@components/HeadingWithInfo';
import AuctionItems from '../../../components/auction/AuctionItems';

export default {
    name: 'UpcomingAuctions',

    components: { AuctionItems, HeadingWithInfo }
};
</script>

<style scoped>

</style>
