<template>
    <div>
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <heading-with-info class="mb-6" :bottom-tag="true" title="Latest News"
                                       info="Lorem ipsum dolor sit amet, consectetur adipisicing elit. Odio, praesentium."></heading-with-info>
                </div>
                <div class="col-12">
                    <latest-news-carousel :details="details"></latest-news-carousel>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import LatestNewsCarousel from '../../../components/home/LatestNewsCarousel';
import HeadingWithInfo from '@components/HeadingWithInfo';

export default {
    name: 'LatestNews',
    props: {
        details: { type: Array, default: null }
    },
    components: { HeadingWithInfo, LatestNewsCarousel }
};
</script>

<style scoped>

</style>
