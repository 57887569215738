<template>
    <div class="">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <heading-with-info class="mb-6" :bottom-tag="true" title="Testimonials"
                                       info="What you think and opine defines what we aim at. Our prime priority will be your satisfaction "></heading-with-info>
                </div>
                <div class="col-12">
                    <testimonials-carousel :details="details"></testimonials-carousel>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import HeadingWithInfo from '@components/HeadingWithInfo';
import TestimonialsCarousel from '@components/home/TestimonialsCarousel';
export default {
    name: 'TheTestimonials',
    props: {
        details: {
            type: Array, default: null
        }
    },
    components: { TestimonialsCarousel, HeadingWithInfo }
};
</script>

<style scoped>

</style>
