<template>
    <section class="pb-5">
        <swiper class="swiper c-swiper" :options="swiperOption">
            <swiper-slide v-for="(item,i) in details" :key="i" class="pb-4 p-2">
                <news-card
                    :id="item.id"
                    :hour="item.created_at"
                    :heading="item.title"
                    :mediaType="item.media_type"
                    :content="item.full_description.substring(0, 145)+' ...'"
                    :img="item.image"
                    :categ="item.category">
                </news-card>
            </swiper-slide>
            <div class="swiper-pagination swiper-pagination-white" slot="pagination"></div>
        </swiper>
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="text-center pt-3">
                        <btn text="View All" design="basic-b" class="px-5 fs-lg-0 py-3 lego-btn-gray-border font-inter-medium"
                             size="md" @click="$router.push('/media/news/')"
                             borderRadius="0"/>
                    </div>
                </div>
            </div>
        </div>
    </section>

</template>
<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
import 'swiper/css/swiper.css';
import NewsCard from '../../components/home/NewsCard';

export default {
    name: 'LatestNewsCarousel',
    components: { NewsCard, Swiper, SwiperSlide },
    props: {
        details: { type: Array, default: null }
    },
    data () {
        return {
            swiperOption: {
                // autoplay: { delay: 3000, disableOnInteraction: false },
                pagination: { el: '.swiper-pagination', clickable: true },
                breakpoints: {
                    1200: { slidesPerView: 3 },
                    992: { slidesPerView: 3 },
                    768: { slidesPerView: 2 },
                    580: { slidesPerView: 2 },
                    320: { slidesPerView: 1 }
                }
            },
            mediaType: this.$route.params.media_type
        };
    }
}; </script>
<style scoped lang="scss">
.img-grayscale {
    -webkit-filter: grayscale(1);
    -webkit-transition: all .3s ease-in-out;
    opacity: .5;

    &:hover {
        filter: unset;
        -webkit-filter: grayscale(0);
        opacity: 1;
    }
}

.swiper-pagination-fraction, .swiper-pagination-custom, .swiper-container-horizontal > .swiper-pagination-bullets {
    bottom: 0px;
    left: 50%;
    transform: translate(-50%, 0);
    width: unset;
    padding: 3px 25px;
    background: #2929293b;
    //border-radius: 15px;
    z-index: 999 !important;
}
</style>
