<template>
    <div>
        <swiper v-if="details.length!==0" class="swiper home-slider" data-mousewheel="1" data-parallax="1"
                :options="swiperOption">
            <swiper-slide v-for="(item, index) in details" :key="index">
                <div class="item bg-cover pos-r" :style="banner(item.banner_image)">
                    <div class="overlay-dark"></div>
                    <div class="container">
                        <div class="row">
                            <div class="col-10 col-md-6 col-lg-6 py-4 py-lg-8 my-1 my-lg-0 text-white">
                                <p class="mb-4 fs-lg-5 font-inter-light">{{ item.banner_text_1 }}</p>
                                <div class="w-90p">
                                    <h6 class="fs-lg-10 d-inline-block w-90p m-0 p-0 mb-1" data-swiper-parallax="-600">
                                        {{ item.banner_text_2 }}
                                    </h6>
                                    <p data-swiper-parallax="-400" class="w-inh fs--2 fs-lg-0 font-inter-light">
                                        {{ item.banner_text_3 }}
                                    </p>
                                    <div class="mt-4">
                                        <a href="#upcoming-auctions" class="fs-lg-2 text-decoration-none cursor-pointer font-inter-medium fl-x fl-a-c">GO TO AUCTIONS
                                            <!--                                            <img src="../../../assets/web/icons/arrow-button.png" class="ml-2 h-2re" alt="">-->
                                            <floating-arrow class="ml-2"/>
                                        </a>
                                    </div>
                                </div>
                                <!--                                <div data-swiper-parallax="-300" class="fl-x-c mt-5">-->
                                <!--                                    <router-link :to="item.url"-->
                                <!--                                                 class="fl-x fl-a-c text-decoration-none btn xeo-btn-5 fs&#45;&#45;2 fs-lg-0 font-inter-light">-->
                                <!--                                        <span>{{ item.url_text }}</span>-->
                                <!--                                        <span class="ml-1 fl-x fl-a-c">-->
                                <!--&lt;!&ndash;                                        <i class="fa fa-chevron-right fs&#45;&#45;1 font-weight-light"></i></span>&ndash;&gt;-->
                                <!--                                            &lt;!&ndash;                                        <img src="../../../assets/mobile/home/chevron-right.png" alt="">&ndash;&gt;-->
                                <!--                                    </span>-->
                                <!--                                    </router-link>-->
                                <!--                                </div>-->
                            </div>
                        </div>
                    </div>
                </div>
                <!--                <span class="bottom-border-item"></span>-->
            </swiper-slide>

            <div class="swiper-pagination swiper-pagination-white" slot="pagination"></div>
        </swiper>
        <swiper v-else class="swiper home-slider" data-mousewheel="1" data-parallax="1" :options="swiperOption">
            <swiper-slide>
                <div class="item bg-cover bg-home-banner">
                    <div class="container">
                        <div class="row">
                            <div class="col-10 col-md-6 col-lg-8 py-4 py-lg-8 my-1 my-lg-0 text-white">
                                <div class="ml-lg-7">
                                    <p class="mb-4 fs-4 font-inter-light">Welcome to <br> Bank e Auctions India.com</p>
                                    <div class="w-90p">
                                        <h6 class="fs-1 fs-lg-9 d-inline-block w-88p m-0 p-0"
                                            data-swiper-parallax="-600">
                                            Best
                                            e Auction Platform in
                                            India
                                            <span data-swiper-parallax="-400"
                                                  class="w-inh fs--2 font-inter-light fs-lg-0 w-90p d-inline-block">
                                                <br>
                                            Browse from more than 1,000 adverts while new one come on daily basis
                                        </span>
                                        </h6>

                                    </div>
                                    <div data-swiper-parallax="-300" class="fl-x-c mt-5">
                                        <router-link to="/about/"
                                                     class="fl-x fl-a-c text-decoration-none btn xeo-btn-5 fs--2 fs-lg-0 font-inter-light">
                                            <span>About Bank e Auctions India.com</span>
                                            <span class="ml-1 fl-x fl-a-c">
                                        <img src="../../../assets/mobile/home/chevron-right.png" alt="">
                                    </span>
                                        </router-link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </swiper-slide>
            <div class="swiper-pagination swiper-pagination-white" slot="pagination"></div>
        </swiper>
    </div>
</template>
<script>
import 'swiper/css/swiper.css';
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
import FloatingArrow from './banner-components/FloatingArrow';

export default {
    name: 'HomeBanner',
    props: {
        details: { type: Array, default: null }
    },
    components: { FloatingArrow, Swiper, SwiperSlide },
    data () {
        return {
            swiperOption:
                    {
                        // autoHeight: true,
                        slidesPerView: 1,
                        spaceBetween: 0,
                        pagination: { el: '.swiper-pagination', clickable: true },
                        // mousewheel: true,
                        parallax: true,
                        centeredSlides: true,
                        // autoplay: true,
                        autoplay: { delay: 8000, disableOnInteraction: false },
                        navigation: { nextEl: '.swiper-button-next', prevEl: '.swiper-button-prev' },
                        breakpoints: {
                            1200: { speed: 1500 },
                            1024: { slidesPerView: 1, spaceBetween: 0, speed: 1000 },
                            768: { slidesPerView: 1, spaceBetween: 0, speed: 800 },
                            640: { slidesPerView: 1, spaceBetween: 0, speed: 800 },
                            320: { slidesPerView: 1, spaceBetween: 0, speed: 800 }
                        }
                    }
        };
    },
    methods: {
        banner (image) {
            return { backgroundImage: 'url(' + image + ')' };
        }
    }
};
</script>
<style scoped>
    .item {
        width: 100%;
        display: flex;
        align-items: center;
    }

    .bg-cover {
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
    }

    .bg-home-banner {
        /*background-image: url('../../assets/img/social-banner/home-banners-mob.jpg');*/
        /*background-image: url('../../../assets/web/home/banner.png');*/
    }

    .bg-home-banner2 {
        /*background-image: url('../../assets/img/social-banner/home-banners-mob2.jpg');*/
        background-image: url('../../../assets/web/home/banner.png');

    }

    .bg-home-banner3 {
        /*background-image: url('../../assets/img/social-banner/home-banners-mob3.jpg');*/
        background-image: url('../../../assets/web/home/banner.png');
    }

    .home-banner-icon {
        display: inline-block;
    }

    .home-banner-icon img {
        width: 1.2rem;
        margin-left: 20px;
    }

    .home-slider h1 {
        line-height: 40px;
    }

    .bottom-border-item {
        background: rgb(218, 173, 40);
        background: linear-gradient(90deg, rgba(218, 173, 40, 1) 0%, rgba(231, 198, 88, 1) 42%, rgba(218, 173, 40, 1) 100%);
        width: 100%;
        height: 5px;
        position: absolute;
        bottom: 0;
    }

    .swiper-pagination-fraction, .swiper-pagination-custom, .swiper-container-horizontal > .swiper-pagination-bullets {
        bottom: 10px;
        left: 50%;
        transform: translate(-50%, 0);
        width: unset;
        padding: 3px 25px;
        background: #2929293b;
        border-radius: 15px;
    }

    .bg-home-banner {
        background-image: url('../../../assets/web/home/banner.png');
    }

    @media (min-width: 992px) {
        .bg-home-banner2 {
            /*background-image: url('../../assets/img/social-banner/home-banners2.jpg');*/
            background-image: url('../../../assets/web/home/banner.png');
        }

        .bg-home-banner3 {
            /*background-image: url('../../assets/img/social-banner/home-banners3.jpg');*/
            background-image: url('../../../assets/web/home/banner.png');
        }

        .home-banner-icon img {
            width: 2rem;
            margin-left: 20px;
        }
    }

    .h-2re {
        height: 2.5rem;
    }

    .overlay-dark {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: rgba(0, 0, 0, 0.48);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 0 100px
    }
</style>
