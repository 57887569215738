<template>
    <div v-if="details" class="of-h" style="min-height: 100vh;">

        <section v-if="dataLoading" class="py-lg-9">
            <div class="text-center pt-7">
                <loading-animation class="text-primary text-5x"/>
            </div>
        </section>

        <section v-else>

            <section id="home">
                <home-banner :details="details.banners" class="mt-4"></home-banner>
                <register-now-section @open-modal="openMemberRegistration"></register-now-section>
            </section>

            <section id="upcoming-auctions" class="py-5">
                <upcoming-auctions/>
            </section>
            <section id="premiumMembership">
                <premium-membership-section></premium-membership-section>
            </section>

            <section id="why-choose-us" class="py-5">
                <why-choose-us></why-choose-us>
            </section>

            <section id="professional-team" v-if="details.teams.length!==0" class="py-4 py-lg-5 bg-4">
                <experienced-and-professional-team :details="details.teams"></experienced-and-professional-team>
            </section>

            <section id="featured-banks" v-if="details.featured_banks.length!==0" class="py-4 py-lg-5 bg-4">
                <featured-banks :details="details.featured_banks"></featured-banks>
            </section>

            <section id="testimonials" v-if="details.testimonials.length!==0" class="py-4 py-lg-5">
                <the-testimonials :details="details.testimonials"></the-testimonials>
            </section>

            <section id="latest-news" v-if="details.latest_news.length!==0" class="py-4 py-lg-5 bg-4">
                <latest-news :details="details.latest_news"></latest-news>
            </section>

        </section>
        <custom-modal no-close-on-backdrop title="" ref="premiumRegisterModal" class="p-0 custom-modal" width="50r"
                      header-color="">
            <section>
                <premium-membership-supports></premium-membership-supports>
                <div class="row py-3">
                    <div class="col-12 col-lg-4 col-md-6 py-3" v-for="(m,i) in membershipSchemes"
                         :key=i>
                        <div @click="setActiveMembership(i)">
                            <dashboard-stat class="c-stat b-2-hover w-100"
                                            :class="{'active': activeMembershipIdx === i}"
                                            color="orange" hoverColor="teal"
                                            icon="" iconAlignmentInvert
                                            :number="m.amount" :name="m.title"
                                            :nameAlignmentInvert="false" shadow="5"
                            />
                        </div>
                    </div>

                    <div class="col-12">
                        <div class="px-2">
                            <btn block text="LOGIN TO UPGRADE" size="lg" icon-alignment="right" icon="fa fa-chevron-right"
                                 class="btn-bg-gradient-secondary border-r--2 border-none "></btn>
                        </div>
                    </div>

                </div>
            </section>

        </custom-modal>
    </div>
</template>

<script>
import HomeBanner from '@components/home/sections/HomeBanner';
import RegisterNowSection from '@components/home/RegisterNowSection';
import UpcomingAuctions from '@components/home/sections/UpcomingAuctions';
import WhyChooseUs from '@components/home/sections/WhyChooseUs';
import ExperiencedAndProfessionalTeam from '@components/home/sections/ExperiencedAndProfessionalTeam';
import FeaturedBanks from '@components/home/sections/FeaturedBanks';
import TheTestimonials from '@components/home/sections/TheTestimonials';
import LatestNews from '../components/home/sections/LatestNews';
import axios from 'secure-axios';
import urls from '../data/urls';
import CustomModal from '@components/ui/CustomModal';
import PremiumMembershipSupports from '@components/auction-details/PremiumMembershipSupports';
import PremiumMembershipSection from '@components/home/PremiumMembershipSection';

export default {
    name: 'Home',

    components: {
        PremiumMembershipSection,
        PremiumMembershipSupports,
        CustomModal,
        LatestNews,
        TheTestimonials,
        FeaturedBanks,
        ExperiencedAndProfessionalTeam,
        WhyChooseUs,
        UpcomingAuctions,
        RegisterNowSection,
        HomeBanner

    },

    data () {
        return {
            details: '',
            dataLoading: false,
            optionItems: [
                { title: 'Individual', icon: 'fa fa-eye' },
                { title: 'Group of Individuals', icon: 'fa fa-eye' },
                { title: 'Proprietor', icon: 'fa fa-eye' },
                { title: 'Partnership', icon: 'fa fa-eye' }
            ],
            activeMembershipIdx: null,
            membershipSchemes: [
                {
                    title: 'Basic',
                    amount: '0.00/ Year'
                },
                {
                    title: 'Premium',
                    amount: '300.00/ Month'
                },
                {
                    title: 'Premium',
                    amount: '2500.00/ Year'
                }
            ]
        };
    },

    beforeMount () {
        this.loadDetails();
    },

    methods: {
        async loadDetails () {
            this.dataLoading = true;
            const response = await axios.get(urls.website.home);
            const json = response.data;
            if (json.error === false) {
                this.details = json.data;
            } else {
                this.$notify('Could not load data, Please try again later', 'Error', {
                    type: 'danger'
                });
            }
            this.dataLoading = false;
            this.$emit('loadingSuccess');
        },
        setActiveMembership (idx) {
            this.activeMembershipIdx = idx;
            console.log('active idx', idx, this.activeMembershipIdx);
        },

        openMemberRegistration () {
            this.$refs.premiumRegisterModal.show();
        }
    }
};

</script>
<style scoped>

</style>
