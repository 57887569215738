<template>
    <div class="b-1 pb-4">
        <div class="card-min-h"
             :style="{'background-image':'url('+ imgUrl +')', 'background-size':'cover', 'background-repeat':'no-repeat', 'background-position':'center'}">
        </div>
        <short-section :link="route" :heading="heading" :source="source" :role="role" :author="author" :size="size"
                       class="mt-4">
            <template #content>
                <p class="fs-lg-1 text-left px-4 mb-lg-3"> {{ content.substring(0, 110) }}</p>
            </template>

            <template #buttons>
                <router-link :to="'our-team#' + item_id" class="fs--1 text-primary-400 text-decoration-none">
                        <span class="fl-x-cc font-inter-medium">
                            <span class="mr-2 color-primary-400">View More</span>
                            <img src="../../assets/mobile/home/icons/chevron-right-primary-24.png" alt="">
                        </span>
                </router-link>
            </template>
        </short-section>

        <!--        </a>-->
    </div>
</template>
<script>
import ShortSection from './ShortSection';

export default {
    name: 'MemberCard',
    components: { ShortSection },
    props: {
        heading: {
            type: String,
            default: ''
        },
        route: {
            type: String,
            default: ''
        },
        role: {
            type: String,
            default: ''
        },
        source: {
            type: String,
            default: ''
        },
        size: {
            type: String,
            default: 'sm'
        },
        author: {
            type: String,
            default: ''
        },
        img: {
            type: Image
        },
        imgUrl: {
            type: String
        },
        content: {
            type: String,
            default: ''
        },
        item_id: {
            type: [String, Number],
            default: ''
        },
        link: {
            type: String,
            default: '/'
        },
        nextLink: {
            type: String,
            default: '/'
        },
        prevLink: {
            type: String,
            default: '/'
        }
    }
};
</script>
<style lang="scss" scoped>
.card-min-h {
    position: relative;
    height: 17rem;
    height: 27rem;

    img {
        width: 100% !important;
        height: 100% !important;
    }
}
</style>
