<template>
    <div class="z-index-2 b-1 bs-3 m-2 border-r-0 pt-5">
        <div class="text-center">
<!--            <i class="fa fa-quote-left fa-2x text-primary"></i>-->
            <img src="../../assets/mobile/home/icons/quote-left-alter-48.png" alt="">
        </div>
<!--        <div class="text-center font-nud-motoya">"</div>-->
        <div class="text-center  px-3 testimonial-content-min-h pos-r">
            <div class="bottom-tag"></div>
            <p class="font-inter-medium">{{ info }}</p>
        </div>
        <div class="fl-y fl-a-c bg-white py-3 h-100 px-3" :class="{'w-80':size ==='sm'}">
            <div class="ml-auto mt-0 top-0 cursor-ponter" v-if="size === 'lg'" @click="goBack"><span
                class="p-2 bg-primary"><i class="fa fa-chevron-left"></i></span></div>
<!--            <img :src="img" alt="" class="border-rounded mb-2 mt-2 img-fluid w-12 w-h-5r"-->
<!--                 :class="{'half-width':size ==='sm','w-12':size === 'lg'}">-->
            <div class="w-h-5r border-rounded" :style="{'background-image':'url('+ img +')', 'background-size':'cover', 'background-repeat':'no-repeat', 'background-position':'center'}"></div>
            <p class="text-5 mt-2" m-0>{{ name }}</p>
<!--            <p class="font-weight-light m-0 text-center">{{ designation }}</p>-->
            <div>
                <slot name="details"></slot>
            </div>
            <div>
                <slot name="footer"></slot>
            </div>
        </div>
    </div>
</template>
<script> export default {
    name: 'TestimonialCard',
    props: {
        title: { type: String, default: 'Title' },
        name: { type: String, default: '' },
        designation: { type: String, default: '' },
        img: { type: String },
        size: { type: String, default: '' },
        info: {
            type: String
        },
        type: { type: String, default: '' },
        backRouteName: { type: String, default: 'Home' },
        detailsPath: { type: String, default: '/' }
    },
    methods: {
        goBack () {
            this.$router.go(-1);
            this.$router.push({ name: this.backRouteName });
            if (this.type === 'board') {
                this.$router.push({ name: 'About' });
            } else {
                this.$router.push({ name: 'History' });
            }
        },
        openMemberPage () {
            if (this.size === 'sm') {
                const routePath = this.detailsPath + this.name.replace(/\s+/g, '');
                this.$router.push({ path: routePath, params: { name: this.name.trim() } });
            }
        }
    }
};
</script>
<style scoped lang="scss">
.border-rounded {
    border-radius: 50%;
}

.bb-1-primary {
    border-bottom: 0.3em solid var(--color-primary);
}

.h-100 {
    height: 100%;
}
.w-h-5r{
    width: 5rem;
    height: 5rem;
}
.testimonial-content-min-h{
    min-height: 4.5rem;
}
.w-12 {
    /*width: 25%;*/
}
.bottom-tag {
    &::after {
        content: '';
        position: absolute;
        width: 10%;
        transform: translateX(-50%);
        height: 2px;
        //background-color: black;
        background-color: var(--bottom-tag-color);
        bottom: 0;
        /*margin-bottom: 1rem;*/
    }
}
</style>
