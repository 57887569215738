<template>
    <div :class="{'text-center':textAlign === 'center','text-left': textAlign === 'left'}">
        <h6 class="font-inter-medium fs-2">{{ heading }}</h6>
        <p class="font-inter-medium fs-1">{{role}}</p>
        <p class="font-weight-normal fs-1" v-if="content !== ''">{{ content }}</p>
        <section v-else class="font-weight-lighter fs-1">
            <slot name="content"></slot>
        </section>
        <div>
            <slot name="buttons"></slot>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ShortSection',
    props: {
        heading: {
            type: String
        },
        link: {
            type: String,
            default: '/'
        },
        role: {
            type: String,
            default: ''
        },
        textAlign: {
            type: String,
            default: 'center'
        },
        content: {
            type: String,
            default: ''
        }
    }
};
</script>

<style scoped>

</style>
