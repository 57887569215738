<template>

    <div class="pos-r container">
        <swiper class="swiper" :options="swiperOption">
            <swiper-slide v-for="(item,index) in details" :key="index" :class="{'mr-md-3 mr-2': sliderRightMargin}">
                <member-card :id="details.id" :item_id="item.id" class="mx-2" link="/our-team/" :imgUrl="item.team_member_image" :heading="item.name"
                             :role="item.designation" :content="item.full_description">
                </member-card>
            </swiper-slide>

        </swiper>
        <div class="d-sm-none swiper-btn swiper-button-prev px-md-4 px-2 fs--1" slot="button-prev"></div>
        <div class="d-sm-none swiper-btn swiper-button-next px-md-4 px-2" slot="button-next"></div>
    </div>

</template>
<script> import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
import 'swiper/css/swiper.css';
import img1 from '../../assets/web/our-teams/team-member-1.jpg';
import img2 from '../../assets/web/our-teams/team-member-2.jpg';
import img3 from '../../assets/web/our-teams/team-member-3.jpg';
import MemberCard from '../../components/home/MemberCard';

export default {
    name: 'ProfessionalsCarousel',
    props: {
        details: {
            type: Array, default: null
        }
    },
    components: { MemberCard, Swiper, SwiperSlide },
    computed: {
        sliderRightMargin (idx) {
            if (idx === this.items.length - 1) {
                return false;
            }
            return true;
        }
    },
    data () {
        return {
            swiperOption: {
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev'
                },
                breakpoints: {
                    1200: { slidesPerView: 3 },
                    768: { slidesPerView: 2 },
                    580: { slidesPerView: 2 },
                    320: { slidesPerView: 1 }
                }
            },
            items: [
                img1, img2, img3, img2
            ]
        };
    }
}; </script>
<style lang="scss" scoped>
main {
    background: #f2f2f2;
}

.swiper-btn {
    position: absolute;
    z-index: 799 !important;
    color: rgba(128, 128, 128, 0.7) !important;
    border: 1px solid rgba(128, 128, 128, 0.9);
    padding: var(--spacer-2);
    font-size: 0.9rem !important;

    &.swiper-button-prev {
        left: -2.5rem;
    }

    &.swiper-button-next {
        right: -2.5rem;
    }
}
</style>
