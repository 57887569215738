<template>
    <div>
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <heading-with-info :bottom-tag="true" title="Why Choose Us?"
                                       info="We are the eagerly anticipated and trusted eAuction platform in Kerala, launched after a series of quality certifications and flawless testing. We provide the optimal environment for safe and secure bidding and e-auction processes."></heading-with-info>
                </div>
                <div class="col-12 col-md-6 order-2 order-md-1">
                    <custom-ul-list class="mt-5"></custom-ul-list>
                </div>
                <div class="col-12 col-md-6 order-1 order-md-2">
                    <div class="fl-x-cc h-100">
                        <div class="p-md-5 px-4">
                            <img src="../../../assets/img/banner/e-auction.png" alt="" class="img-fluid">
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
import HeadingWithInfo from '@components/HeadingWithInfo';
import CustomUlList from '@components/home/CustomUlList';

export default {
    name: 'WhyChooseUs',
    components: { CustomUlList, HeadingWithInfo }
};
</script>

<style scoped>

</style>
