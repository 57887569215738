<template>
    <section class="">
        <swiper class="swiper c-swiper" :options="swiperOption">
            <swiper-slide v-for="(item,i) in details" :key="i" class="pb-5">
                <testimonial-card
                    :name="item.testimonial_user"
                    :info="item.testimonial_description"
                    :img="item.testimonial_user_image"
                >
                </testimonial-card>
            </swiper-slide>
            <div class="swiper-pagination swiper-pagination-white" slot="pagination"></div>
        </swiper>
    </section>

</template>
<script> import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
import 'swiper/css/swiper.css';
// import img1 from '../../assets/img/partners/partner-1.jpg';
// import img2 from '../../assets/img/partners/partner-2.jpg';
// import img3 from '../../assets/img/partners/partner-3.jpg';
// import img4 from '../../assets/img/partners/partner-4.jpg';
import img1 from '../../assets/web/home/testimonials/testimonial-1.jpg';
import img2 from '../../assets/web/home/testimonials/testimonial-2.jpg';
import img3 from '../../assets/web/home/testimonials/testimonial-3.jpg';
import TestimonialCard from '@components/home/TestimonialCard';

export default {
    name: 'TestimonialsCarousel',
    props: {
        details: {
            type: Array, default: null
        }
    },
    components: { TestimonialCard, Swiper, SwiperSlide },
    data () {
        return {
            swiperOption: {
                // autoplay: { delay: 3000, disableOnInteraction: false },
                pagination: { el: '.swiper-pagination', clickable: true },
                breakpoints: {
                    1200: { slidesPerView: 3 },
                    768: { slidesPerView: 2 },
                    580: { slidesPerView: 2 },
                    320: { slidesPerView: 1 }
                }
            },
            items: [
                {
                    info: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Itaque, praesentium.',
                    img: img1,
                    name: 'Joseph Knol'
                },
                {
                    info: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Itaque, praesentium.',
                    img: img2,
                    name: 'Arun S Pillai'
                },
                {
                    info: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Itaque, praesentium.',
                    img: img3,
                    name: 'Izabella Noyal'
                },
                {
                    info: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Itaque, praesentium.',
                    img: 'https://images.unsplash.com/photo-1541576980233-97577392db9a?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=584&q=80',
                    name: 'Izabella Noyal'
                }
            ]
        };
    }
}; </script>
<style scoped lang="scss">
.img-grayscale
{
    -webkit-filter: grayscale(1);
    -webkit-transition: all .3s ease-in-out;
    opacity: .5;

    &:hover {
        filter: unset;
        -webkit-filter: grayscale(0);
        opacity: 1;
    }
}
    .swiper-pagination-fraction, .swiper-pagination-custom, .swiper-container-horizontal > .swiper-pagination-bullets {
        bottom: 0px;
        left: 50%;
        transform: translate(-50%, 0);
        width: unset;
        padding: 3px 25px;
        background: #2929293b;
        //border-radius: 15px;
        z-index: 999!important;
    }
</style>
