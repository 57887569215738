<template>
    <div>
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <heading-with-info class="mb-6" :bottom-tag="true"
                                       title="Introducing our Multifaceted Experts’ Team"
                                       info="We are a creative santeam of highly motivated and goal-oriented individuals who
                                       not only bring our own professional virtues, innovative traits, unmatched skills, and ideologies
                                        but can also elevate each other's talents and strengths. Similarly, we excel at tackling the most
                                       challenging tasks and providing real-time impactful solutions to our clients with ease and unmatched accuracy.">
                        <template #title>
                            <p class="fs-lg-3 font-inter-medium">Introducing our Multifaceted Experts’ Team</p>
                            <h5 class="font-inter-semi-bold mb-4 fs-lg-5">Empowering Success:Your All-In-One Hub for
                                Multifaceted Business Excellence</h5>
                            <!--                        <p class=""> extreme ease and matchWe are a creative team of quickly motivated and goal-oriented individuals who not only bring our own professional virtues, innovative traits, unmatched skills, and ideologies but can elevate each others’ talent and strength. In the same manner, we can figure out crack the most stringent dares and offer real-time impactful answers to our clients withless accuracy.</p>-->
                        </template>
                    </heading-with-info>
                </div>
            </div>
        </div>
        <professionals-carousel :details="details"></professionals-carousel>
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="text-center pt-5">
                        <btn text="View All" design="basic-b"
                             class="px-5 fs-lg-0 py-3 lego-btn-gray-border font-inter-medium"
                             size="md" @click="$router.push('/our-team/')"
                             borderRadius="0"/>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import HeadingWithInfo from '@components/HeadingWithInfo';
import img1 from '../../../assets/web/our-teams/team-member-1.jpg';
import img2 from '../../../assets/web/our-teams/team-member-2.jpg';
import img3 from '../../../assets/web/our-teams/team-member-3.jpg';
import ProfessionalsCarousel from '@components/home/ProfessionalsCarousel';

export default {
    name: 'ExperiencedAndProfessionalTeam',
    props: {
        details: {
            type: Array, default: null
        }
    },
    components: { ProfessionalsCarousel, HeadingWithInfo },
    data () {
        return {
            images: [
                img1, img2, img3
            ]
        };
    }
};
</script>

<style scoped>

</style>
