<template>
    <div>
        <div class="root-main text-white py-4 fs-lg-5 font-inter-light">
            <div class="container" @click="$refs.popUpModal.show()">
                <div class="section-content row font-inter-light fl-a-c">
                    <div class="col-md-6 fl-x md-fl-j-e">
                       <div class="font-inter-light fs-lg-2 m-0 sm-w-100p text-center text-lg-right">
                        <p class="m-0">Get More Features With Premium Membership</p></div>
                    </div>
                    <div class="col-md-6 fl-x md-fl-j-s fl-j-c fl-a-c">
                        <div class="fl-x-cc">
                            <span class="fs-lg-1 fs--3 mx-lg-4 mx-3 fl-x fl-a-c d-sm-none">
                            <img src="../../assets/mobile/home/chevron-right-double-24.png" alt="">
                        </span>
                            <span>
                            <router-link to="/" class="text-decoration-none font-inter-light fs-lg-2 pos-r">Register Now <span
                                class="underline-item"></span></router-link>
                        </span>
                            <span class="fs-lg-1 fs--3 mx-lg-4 ml-2 fl-x mt-2 fl-a-c d-lg-none d-md-none">
                            <img src="../../assets/mobile/home/chevron-right-double-24.png" alt="">
                        </span>
                        </div>
                    </div>

                </div>
            </div>
        </div>

        <modal class="c-modal-1 no-close " :no-close-on-backdrop="true" title=""
               ref="popUpModal" width="50r" header-color="">
            <div class="modal-sm-max-h-70vh thin-scrollbar">
                <div class="text-center mb-3 fl-te-c mr-2 ml-2">
                    <div></div>
                    <img src="../../assets/web/home/BANK-E-AUCTION-LOGO-icon.png" class="top-icon" loading="lazy" alt="">
                    <i @click="$refs.popUpModal.close()" class="fa fa-close"/>
                </div>

                <h6 class="font-weight-bold text-center">Bank e Auctions India.com {{ selected }} Membership</h6>
                <div class="px-3 m-w25 ">
                    <div v-for="(feature, index) in features" :key="index" class="row p-2">
                        <div class="col-10">{{ feature }}</div>
                        <div class="col-2">
                            <div class="fl-x fl-j-c">
                                <i class="fa fa-check text-secondary"/>
                            </div>

                        </div>
                    </div>
                </div>
                <div class="row m-0 mt-3 small font-weight-bold pb-3 px-2">
                    <div class="col-12 col-md-3 pr-md-0">
                        <div @click="setAccountDetails('Free')" v-bind:class="{ 'border-bg-8' : selected === 'Free'}"
                             class="stat-card-min-h fl-x-cc b-2 round-1 p-2 cursor-pointer ">Free
                        </div>
                    </div>
                    <div class="col-12 col-md-3 pr-md-0">
                        <div @click="setAccountDetails('Premium')" v-bind:class="{ 'border-bg-8' : selected === 'Premium'}"
                             class="stat-card-min-h fl-x-cc mt-3 mt-md-0 b-2 round-1 p-2 cursor-pointer">Premium<br/>₹ 3000.00/3 Months
                        </div>
                    </div>
                    <div class="col-12 col-md-3 pr-md-0">
                        <div @click="setAccountDetails('Gold')" v-bind:class="{ 'border-bg-8' : selected === 'Gold'}"
                             class="stat-card-min-h fl-x-cc mt-3 mt-md-0 b-2 round-1 p-2 cursor-pointer">Gold<br/>₹ 5000.00/6 Months
                        </div>
                    </div>
                    <div class="col-12 col-md-3">
                        <div @click="setAccountDetails('Platinum')"
                             v-bind:class="{ 'border-bg-8' : selected === 'Platinum'}"
                             class="stat-card-min-h fl-x-cc mt-3 mt-md-0 b-2 round-1 p-2 cursor-pointer">Platinum<br/>₹ 10000.00/Annum
                        </div>
                    </div>
                    <!--                <div @click="setAccountDetails('Free')" v-bind:class="{ 'border-bg-8' : selected === 'Free'}"-->
                    <!--                     class="col b-2 round-1 m-1 p-2 cursor-pointer">Free-->
                    <!--                </div>-->
                    <btn v-if="selected !== 'Free'" @click="buyPremium"
                         class="btn-block m-1 mt-3" color="secondary">
                        LOGIN TO UPGRADE<i class="fa fa-chevron-right ml-2"/></btn>
                    <btn v-else @click="registerFree" class="btn-block m-1 mt-3" color="secondary">
                        REGISTER<i class="fa fa-chevron-right ml-2"/></btn>
                </div>
            </div>
        </modal>

    </div>
</template>

<script>
export default {
    name: 'RegisterNowSection',

    data () {
        return {
            features: [],
            selected: ''
        };
    },

    mounted () {
        this.setAccountDetails('Premium');
    },

    methods: {
        buyPremium () {
            this.$refs.popUpModal.close();
            this.$router.push({ path: '/login/#' + this.selected });
        },
        registerFree () {
            this.$refs.popUpModal.close();
            this.$router.push({ path: '/register/' });
        },
        setAccountDetails (account) {
            this.selected = account;
            if (account === 'Premium') {
                this.features = [
                    '3 Months Premium Access',
                    'View All Auction Details ',
                    'Daily Email Alerts',
                    '6 Months of Archives',
                    'Dedicated personalized dashboard including bid history',
                    'Complete photos and videos related to Auctioned items',
                    'Legal advisory related to the auction property (2 nos)',
                    'Expert Consultation during property acquisition and price analysis '
                ];
            } else if (account === 'Gold') {
                this.features = [
                    '6 Months Premium Access',
                    'View All Auction Details ',
                    'Daily Email Alerts',
                    '1 Year of Archives',
                    'Dedicated personalized dashboard including bid history',
                    'Complete photos and videos related to Auctioned items',
                    'Legal advisory related to the auction property (5 nos)',
                    'Expert Consultation during property acquisition and price analysis '
                ];
            } else if (account === 'Platinum') {
                this.features = [
                    '12 Months Premium Access',
                    'View All Auction Details',
                    'Daily Email Alerts',
                    'Unlimited Years of Archives',
                    'Dedicated personalized dashboard including bid history',
                    'Complete photos and videos related to Auctioned items',
                    'Legal advisory related to the auction property (12 nos)',
                    'Expert Consultation during property acquisition and price analysis ',
                    'Dedicated Relationship Manager',
                    'Assistance for identification of the property till registration '

                ];
            } else if (account === 'Free') {
                this.features = [
                    'Daily Email Alerts',
                    'View Partial Auction Details'
                ];
            }
        }
    }
};
</script>

<style scoped lang="scss">
    .root-main {
        background: url("../../assets/web/home/bg/gold-strip-web.png");
        background-size: cover;
        background-repeat: no-repeat;
        width: 100%;
    }

    .section-content {
        /*display: flex;*/
        /*align-items: center;*/
        /*justify-content: center;*/
        /*font-family: 'font-inter-light';*/
    }

    .top-icon {
        height: 2rem !important;
    }

    .underline-item {
        position: absolute;
        width: 100%;
        height: 1px;
        bottom: -7px;
        left: 0;
        background-color: rgba(228, 233, 242, 0.6);
    }

    .main-content-res {
        width: 60%;
        @media (min-width: 992px) {
            width: 40%;
        }
        //@media (min-width: 768px) and (max-width: 1024px) {
        //    width: 50%;
        //}
    }

    .m-w25 {
        min-height: 20rem !important;
    }
</style>
