<template>

    <table class="w-100 c-supports-table">
        <thead>
        <th></th>
        <th>Basic</th>
        <th class="text-primary">Premium</th>
        </thead>
        <tbody>
        <tr v-for="(item,i) in supports" :key="i">
            <td class="fs--1">{{ item.text }}</td>
            <td>
                <i class="fa fa-check fa-2x text-primary" v-if="item.basic"></i>
                <i class="fa fa-close fa-2x text-primary" v-else></i>
            </td>
            <td>
                <i class="fa fa-check fa-2x text-primary" v-if="item.premium"></i>
                <i class="fa fa-close fa-2x text-primary" v-else></i>
            </td>
        </tr>
        </tbody>
    </table>

</template>

<script>
export default {
    name: 'PremiumMembershipSupports',
    data () {
        return {
            supports: [
                {
                    text: 'Get Unlimited image access on Bank e Auctions India.com',
                    basic: true,
                    premium: true
                },
                {
                    text: 'Detaied Video about properties',
                    basic: false,
                    premium: true
                },
                {
                    text: 'Get Property Data Sheet for every auction',
                    basic: false,
                    premium: true
                },
                {
                    text: 'Get Unlimited image access on Bank e Auctions India.com',
                    basic: false,
                    premium: true
                },
                {
                    text: 'Legal advisor support at any time',
                    basic: false,
                    premium: true
                }
            ]
        };
    }
};
</script>

<style scoped lang="scss">
.c-supports-table {
    th, td {
        padding: var(--spacer-1) var(--spacer-2);
        @media (min-width: 992px) {
            padding: var(--spacer-2) var(--spacer-3);
        }
        border-bottom: 1px solid rgba(128, 128, 128, 0.3);
    }
}
</style>
